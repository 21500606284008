import React from 'react'

import {Link} from 'gatsby'
import Layout from '../../components/english_layout'
import SEO from '../../components/seo'
import Banner from '../../components/banner'
import Foot from '../../components/footer'

import '../../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import {responsive} from '../../utils/responsive'

const AboutPage = () => {
    return (<Layout languageSwitchLink="/about">
        <SEO title="À propos"/>
        <Banner class_suffix="deep"/>
        <div className="about-container">
            <div className="about-content">
                <p>
                    PolyCyber is the cybersecurity branch of PolyHx (pronounced Poly Hacks), a gathering of informatics clubs of Polytechnique Montreal. Since 2013, we aim to develop the interest of our student community for cybersecurity.
                </p>
                <h3>Our Missions</h3>
                <ul>
                    <li>Promote knowledge transfers</li>
                    <li>Represent Polytechnique Montreal in cybersecurity competitions</li>
                    <li>Build relationships with companies</li>
                    <li>Raise awareness for Cybersecurity</li>
                </ul>
                <h3>Our Activities</h3>
                During the whole year, PolyCyber organises various activities such as technical workshops, conferences and competitions. Furthermore, we frequently participate in competitions such as Capture the Flag. To learn more about our activities, take a look at our blog <a href="blog">here</a>
                <h3>Our support</h3>
                Our activities are only made possible thanks to our sponsors' support. You can learn more information about them on the <a href="partners">Sponsors</a> page.
            </div>
        </div>
        <Foot></Foot>
    </Layout>)
}
export default AboutPage
